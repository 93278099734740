const defaultState = () => {
    return {
        appQuestions: [],
        appSubQuestions: [],
        appSections: [],
        activeQuestion: '',
        activeSection: '',
    }
}

const state = defaultState()

const getters = {
    getActiveSection: state => state.activeSection,
    getActiveQuestion: state => state.activeQuestion,
    getQuestions: state => state.appQuestions,
    getSubQuestions: state => state.appSubQuestions,
    getSections: state => state.appSections,
}

const actions = {
    fetchActiveSection({ commit }, data) {
        commit('setActiveSection', data);
    },

    fetchActiveQuestion({ commit }, data) {
        commit('setActiveQuestion', data);
    },

    fetchQuestions({ commit }, data) {
        commit('setQuestions', data);
    },

    fetchSubQuestions({ commit }, data) {
        commit('setSubQuestions', data)
    },

    fetchSections({ commit }, data) {
        commit('setSections', data)
    },

    addAnswerLocally({ commit, getters }, data) {
        let allQuestions = getters.getQuestions;

        allQuestions.forEach(v => {
            if (v.questionId === data.question_id) {
                v.questionAnswer = data.answer;
                v.coQuestionAnswer = data.co_answer;
            }
        });

        commit('setQuestions', allQuestions);
    },

    addMultipleAnswersLocally({ commit, getters }, data) {
        let allQuestions = getters.getQuestions;

        data.questions.forEach((v) => {
            allQuestions.forEach(x => {
                if (x.questionId === v.questionId)
                    x.questionAnswer = v.questionAnswer || null;
            });
        });

        commit('setQuestions', allQuestions);
    },

    addMainAndSubAnswersLocally({ commit, getters }, data) {
        let allQuestions = getters.getQuestions;
        let allSubQuestions = getters.getSubQuestions;

        allQuestions.forEach(v => {
            if (v.questionId === data.question_id)
                v.questionAnswer = data.main_answer;
        });

        data.sub_questions.forEach((v, i) => {
            allSubQuestions.forEach(x => {
                if (x.questionId === v.questionId)
                    x.questionAnswer = data.sub_answers[i] || null;
            });
        });

        commit('setQuestions', allQuestions);
        commit('setSubQuestions', allSubQuestions);
    },

    updateQueObjectWithAnswer({ commit, getters }, data) {
        let allQuestions = getters.getQuestions;

        if (data?.length) {
            data.forEach((v) => {
                allQuestions.forEach(x => {
                    if (x.questionId === v.questionId) {
                        x.questionAnswer = v.questionAnswer || null;
                        x.coQuestionAnswer = v.coQuestionAnswer || null;
                    }
                });
            });
        }

        commit('setQuestions', allQuestions);
    },

    resetQuestionnaireStore({ commit }) {
        commit('resetQuestionnaireState')
    }
}

const mutations = {
    setActiveSection: (state, activesection) => state.activeSection = activesection,
    setActiveQuestion: (state, activequestion) => state.activeQuestion = activequestion,
    setQuestions: (state, questions) => state.appQuestions = questions,
    setSubQuestions: (state, subquestions) => state.appSubQuestions = subquestions,
    setSections: (state, sections) => state.appSections = sections,

    resetQuestionnaireState(state) {
        Object.assign(state, defaultState())
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}